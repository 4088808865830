<template>
    <div class="tarot">
        <baydin-navbar title="ထီ ကံစမ်း" />
        <div class="container baydin-bg-img text-center">
            <h3 class="text-white pb-5">သင့် ရဲ့ မွေးနေ့ကို ဖြည့်ပါ။</h3>
            <div class="row form-group">
                <label for="date" class="col-3 my-auto text-white"
                    >Birthday:</label
                >
                <div class="col-9 d-flex">
                    <input
                        v-model="birthday"
                        type="date"
                        class="form-control"
                    />
                </div>
            </div>

            <div class="text-center mt-3 mb-3">
                <button class="btn btn-primary" @click="getAnswer">
                    Submit
                </button>
            </div>

            <div v-if="remainder && remainder.name">
                <h2 class="text-white">{{ remainder.name }}</h2>
            </div>

            <div v-if="suggestion && suggestion.descripton">
                <p class="text-white">{{ suggestion.descripton }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import BaydinNavbar from '@/components/reusable/BaydinNavbar.vue';

export default {
    components: {
        'baydin-navbar': BaydinNavbar,
    },

    data() {
        return {
            birthday: '',
            remainder: {
                name: '',
            },
            suggestion: {
                descripton: '',
            },
        };
    },

    methods: {
        getAnswer() {
            var baydin_token = localStorage.getItem('baydin_token');

            const date = new Date(this.birthday);
            let formatted_date =
                String(date.getDate()).padStart(2, '0') +
                '-' +
                String(date.getMonth() + 1).padStart(2, '0') +
                '-' +
                date.getFullYear();
            console.log(formatted_date, 'format');
            var formdata = new FormData();
            formdata.append('birth_date', formatted_date);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${baydin_token}`,
                },
            };

            fetch(
                'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/lotteryfotune/answer',
                requestOptions,
            )
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    this.remainder = result.data.remainder;
                    this.suggestion = result.data.suggestion;
                })
                .catch(error => console.log('error', error));
        },
    },
};
</script>
<style lang="scss">
.baydin-bg-img {
    background-image: url('~@/assets/img/baydin/horoscope.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.pb-20 {
    padding-bottom: 20px;
}
</style>
